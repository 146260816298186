<template>
  <div class="index-map full-box">
    <div class="index-map-content">
      <div class="index-map-main unfull-box">
        <div class="index-map-main-map" id="eachartsMap"></div>
        <div class="index-map-main-fun">
          <p class="map-main-fun-title"><span>AD-VC</span>在中国</p>
          <div class="map-main-fun-list">
            <div v-for="(city, idx) in cityList" :key="idx" :class="{ highlight: city.hasBranchOffice }">
              {{ city.name }}
            </div>
          </div>
          <div class="yi-btn map-main-fun-btn" @click="doClick">查看详情</div>
        </div>
      </div>
      <div class="index-map-swiper-box">
        <div class="index-map-swiper">
          <div class="swiper-wrapper-map">
            <div class="swiper-slide-map" v-for="(company, idx) in slideList" :key="idx">
              <img :src="company.url" :alt="company.name" style="object-fit: cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-map-mask full-box"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import Axios from 'axios';
export default {
  inject: ['isMobile'],
  data() {
    return {
      cityList: [],
      publicPath: process.env.BASE_URL,
      companyList: [],
      slideList: [],
    };
  },
  methods: {
    renderMap() {
      let myChart = this.$echarts.init(document.getElementById('eachartsMap'));
      myChart.showLoading({
        text: '数据加载中...',
        color: '#fff',
        textColor: '#ffffc2',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0,
      });

      let convertData = (onlyBranch = false) => {
        let res = [];
        this.cityList
          .filter((e) => (onlyBranch ? e.hasBranchOffice : true))
          .map((t) => {
            res.push({
              name: t.name,
              value: [t.lng, t.lat, 111],
            });
          });
        return res;
      };

      let instance = Axios.create({
        baseURL: `${this.publicPath}`,
        timeout: 3000,
      });
      instance.get('./china.json').then((res) => {
        myChart.hideLoading();
        this.$echarts.registerMap('china', res.data);
        let option = {
          background: 'transparent',
          geo: {
            zoom: 1.2,
            map: 'china',
            show: false,
            roam: false,
            label: {
              emphasis: {
                show: false,
              },
            },
          },
          series: [
            {
              zoom: 1.2,
              type: 'map',
              map: 'china',
              geoIndex: 1,
              roam: false,
              itemStyle: {
                normal: {
                  areaColor: 'rgba(255,255,255,0.95)', //地图块颜色#DCE2F1  浅蓝
                  borderColor: '#dddddd',
                },
                emphasis: {
                  areaColor: 'rgba(255,255,255,0.95)',
                  borderColor: '#dddddd',
                },
              },
            },
            // {
            //   name: '城市',
            //   type: 'scatter',
            //   coordinateSystem: 'geo',
            //   data: convertData(),
            //   symbolSize: function (val) {
            //     return val[2] / 20;
            //   },
            //   label: {
            //     normal: {
            //       formatter: '{b}',
            //       position: 'right',
            //       show: false,
            //     },
            //     emphasis: {
            //       show: true,
            //     },
            //   },
            //   itemStyle: {
            //     normal: {
            //       color: '#0046E6',
            //     },
            //   },
            // },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              data: convertData(true),
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'right',
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: '#bd192f', // 红色
                  shadowBlur: 25,
                  shadowColor: '#666',
                },
              },
              zlevel: 1,
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    initData() {
      new Swiper('.index-map-swiper', {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 20,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        speed: 700,
        centeredSlides: true,
        on: {
          slideChangeTransitionStart: function () {
            // const img = $('.index-map-swiper-box').find('.swiper-slide img').eq(this.activeIndex).attr('src');
            // $('.index-map-swiper-box').find('.swiper-slide img').css('border', 'none');
            // $('.index-map-swiper-box')
            //   .find('.swiper-slide img')
            //   .eq(this.activeIndex)
            //   .css('border', '3px solid #0046E6');
            // $('.index-map').css('background-image', 'url(' + img + ')');
          },
        },
      });

      // $('.map-main-fun-list div').hover(function (e) {
      //   $('.map-main-fun-list div').css('color', '#828282');
      //   $(e.target).css('color', '#0046e6');
      //   const city = $(e.target).text();
      //   let newData = [{ name: '长沙', value: [112.98626, 28.25591] }];
      //   data.forEach((item) => {
      //     if (item.name === city) {
      //       newData = [
      //         {
      //           name: item.name,
      //           value: [geoCoordMap[`${city}`][0], geoCoordMap[`${city}`][1], item.value],
      //         },
      //         { name: '长沙', value: [112.98626, 28.25591, 222] },
      //       ];
      //       console.log('112.98626, 28.25591, 522');
      //     }
      //   });

      //   option.series[2].data = newData;
      //   myChart.setOption(option);
      // });
    },
    async queryCompanyList() {
      this.companyList = await this.$api.companyQueryCompanyList({});
      let slideList = [];
      this.companyList.map((t) => {
        if (t.attachmentList) {
          t.attachmentList.map((e) => {
            slideList.push({
              id: t.id,
              url: e.fileUrl,
              name: t.name,
            });
          });
        }
      });
      this.slideList = slideList;
    },
    doClick() {
      let obj = { path: `/branch` };
      if (this.isMobile) {
        this.$router.push(obj);
      } else {
        this.$router.replace(obj);
      }
    },
    initScroll() {
      let box = $('.swiper-wrapper-map');
      // let transX = 0;
      box.mousedown((e) => {
        let x = e.pageX;
        let scrollX = box.scrollLeft();
        // transX = 0;
        $(document).mousemove((e1) => {
          e1.preventDefault();
          let xNew = e1.pageX;
          let xChange = x - xNew;
          let move = scrollX + xChange / 2;
          scrollX = box.scrollLeft(move).scrollLeft();
          // transX = move;
        });
      });
      $(document).mouseup(() => {
        $(document).unbind('mousemove');
        // box.animate({ scrollLeft: transX }, 100);
      });
    },
  },
  async mounted() {
    this.initScroll();
    this.cityList = await this.$api.regionQueryProvincialCapitalList({});
    await this.queryCompanyList();
    this.renderMap();
  },
};
</script>

<style scoped lang="scss">
.index-map {
  padding: 20px 0;
  background-image: url('~@images/map/bg.jpg');
  background-position: 0 -2px;
  position: relative;
  height: 867px;
}
.index-map-main {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.index-map-main-map {
  width: calc(100% - 400px);
  margin-top: 40px;
  height: 570px;
}
.index-map-main-fun {
  width: 385px;
  height: 460px;
  background-color: #edf0f9;
  margin-top: 50px;
  padding: 40px;
  box-sizing: border-box;
}
.map-main-fun-title {
  font-size: 36px;
  color: #232323;
  font-weight: 300;
  margin: 0;
  span {
    font-weight: 500;
  }
}
.map-main-fun-search {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #78797b;
  margin: 20px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  input {
    border: none;
    margin: 7px 0;
    font-size: 14px;
    color: #6d6d6d;
    padding: 0 15px;
    background-color: initial;
    outline: none;
    width: 80%;
    box-sizing: border-box;
    border-right: 1px solid #78797b;
  }
  img {
    margin-left: 17px;
    height: 20px;
  }
}
.map-main-fun-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  div {
    width: calc(100% / 4);
    font-size: 14px;
    line-height: 1.3;
    color: #828282;
    border-right: 1px solid #828282;
    margin-bottom: 10px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.map-main-fun-conditions {
  margin: 10px 0;
}
.map-main-fun-conditions-title {
  font-size: 14px;
  color: #232323;
  font-weight: bold;
}
.map-main-fun-conditions-box {
  display: flex;
  justify-content: space-between;
}
.map-main-fun-conditions-box-item {
  width: 46%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  border: 1px solid #78797b;
  border-radius: 5px;
  padding: 5px 15px;
  color: #828282;
  img {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
}
.map-main-fun-btn {
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  background-color: #bd192f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
  cursor: pointer;
}
.index-map-swiper-box {
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.15);
}
.index-map-swiper {
  height: 200px;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.index-map-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(31, 32, 34, 0.5);
  z-index: 1;
}
.index-map-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.highlight {
  color: #bd192f !important;
}

.swiper-wrapper-map {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 200px;
  &::-webkit-scrollbar {
    display: none;
  }
  .swiper-slide-map {
    white-space: nowrap;
    display: inline-block;
    width: 220px;
    height: 100%;
    margin: 0 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-user-drag: none;
    }
  }
}

.mobile {
  .index-map {
    height: 700px;
  }
  .index-map-main {
    padding: 10px 0 0;
    &.unfull-box {
      width: auto;
    }
  }
  .index-map-main-map {
    display: none;
  }
  .index-map-main-fun {
    padding: 14px 10px;
    width: 100%;
    margin: 20px 10px 30px;
    height: auto;
    border-radius: 4px;
  }
}
</style>
