<template>
  <div class="home">
    <IndexHeader />
    <IndexNews />
    <IndexChain />
    <IndexField />
    <IndexMap />
    <IndexService />
    <IndexWall />
  </div>
</template>

<script>
import IndexHeader from '@/components/IndexHeader.vue';
import IndexNews from '@/components/IndexNews.vue';
import IndexChain from '@/components/IndexChain.vue';
import IndexField from '@/components/IndexField.vue';
import IndexMap from '@/components/IndexMap.vue';
import IndexService from '@/components/IndexService.vue';
import IndexWall from '../components/IndexWall.vue';

export default {
  name: 'Home',
  components: {
    IndexHeader,
    IndexNews,
    IndexChain,
    IndexField,
    IndexMap,
    IndexService,
    IndexWall,
  },
};
</script>
