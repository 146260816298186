<template>
  <div class="full-box index-header">
    <div class="index-header-swiper swiper-container">
      <swiper :options="swiperOptions" ref="mySwiper" @click-slide="doClick">
        <swiper-slide v-for="(e, idx) in banners" :key="idx">
          <img :src="e.imageUrl" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev">
          <img src="@images/icon/prev.png" />
        </div>
        <div class="swiper-button-next" slot="button-next">
          <img src="@images/icon/next.png" />
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      banners: [],
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<div class="${className}" data-index="${index}"></div>`;
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    doClick(e) {
      if (!e.redirectUrl || e.redirectUrl.match(/http[s]{0,1}:\/\/([\w.]+\/?)\S*/gi)) {
        return;
      }
      window.open(e.redirectUrl, '_blank');
    },
  },
  async mounted() {
    this.banners = await this.$api.slideshowQuerySlideshowList();
  },
};
</script>

<style scoped lang="scss">
.index-header-swiper {
  height: 750px;
  position: relative;
  .swiper-container {
    height: 100%;
    ::v-deep .swiper-slide {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    ::v-deep .swiper-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: 0 auto;
      text-align: right;
      display: flex;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      &-bullet {
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 50%;
        margin-right: 25px;

        &.swiper-pagination-bullet-active {
          width: 24px;
          height: 8px;
          background: #ffffff;
          border-radius: 10px;
        }
      }
    }

    &:hover {
      ::v-deep .swiper-button-next,
      .swiper-button-prev {
        display: block !important;
      }
    }
    ::v-deep .swiper-button-prev,
    .swiper-button-next {
      display: none;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.25);
      overflow: hidden;
      img {
        width: 75%;
        opacity: 0.5;
        padding-top: 11px;
      }
    }
    ::v-deep .swiper-button-prev {
      margin-left: 14px;
      img {
        padding-left: 10px;
      }
    }
    ::v-deep .swiper-button-next {
      margin-right: 14px;
      img {
        padding-right: 11px;
      }
    }
  }
}

.index-header {
  background-color: #f0f0fa;
}

.mobile {
  .index-header-swiper {
    margin-top: 50px;
    height: 147px;
  }
}
</style>
