<template>
  <div class="logo-wall swiper-container-logo-wall" v-if="linkList && linkList.length">
    <div class="title">合作伙伴</div>
    <img src="@images/logo-wall/遮盖层.png" class="cover1" />
    <img src="@images/logo-wall/遮盖层2.png" class="cover2" />
    <div v-for="(link, idx) in linkList" :key="idx">
      <vue-seamless-scroll :data="link" :class-option="getOptions(idx)" class="seamless-warp">
        <ul class="ul-item">
          <li class="li-item" v-for="banner in link" :key="banner.id">
            <img :src="banner.imageUrl" :alt="banner.tip" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  inject: ['isMobile'],
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      linkList: [],
    };
  },
  methods: {
    initData() {},
    arrTrans(num, arr) {
      // 一维数组转换为二维数组
      const iconsArr = []; // 声明数组
      arr.forEach((item, index) => {
        const page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) {
          // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    },
    getOptions(index) {
      return {
        direction: 3,
        limitMoveNum: 2,
        step: this.isMobile ? Math.max(1.2 - 0.1 * index, 1) : Math.max(2.3 - 0.1 * index, 1),
      };
    },
  },
  async mounted() {
    this.initData();
    let res = await this.$api.linkQueryLinkList({});
    this.linkList = this.arrTrans(6, res);
  },
};
</script>

<style scoped lang="scss">
.seamless-warp {
  width: 1100px;
  height: 96px;
  margin: 0 auto 10px;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 180px;
        height: 96px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          border: 1px solid #e2e5ea;
        }
      }
    }
  }
}
.logo-wall {
  position: relative;
  width: 1100px;
  margin: 0 auto 100px;
  height: auto;
  .title {
    font-size: 36px;
    color: #232323;
    text-align: center;
    font-weight: 600;
    margin: 72px auto 50px;
    padding: 30px 0px;
  }
  .logos {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    .logo {
      width: 195px;
      height: 70px;
      /* border: solid 2px #C8C8C8; */
      /* overflow: hidden; */
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0px;
    }
  }
  .btn-join {
    width: 320px;
    height: 40px;
    color: white;
    background-color: #bd192f;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 3px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .btn-join:hover {
    animation: 1s rainbow infinite alternate;
    cursor: pointer;
  }
}

@keyframes rainbow {
  0% {
    background: #0046e6;
  }
  100% {
    background: #2466ff;
  }
}
.logo-list {
  display: flex;
  justify-content: space-between;
  width: 1380px;
  padding: 12px 10px;
}
.logo-list-item {
  width: 180px;
  height: 96px;
  img {
    width: 100%;
    height: 100%;
    border: 1px solid #e2e5ea;
  }
}
.cover1 {
  position: absolute;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  left: 0;
}
.cover2 {
  position: absolute;
  z-index: 1;
  right: 0;
}

.mobile {
  .logo-wall {
    width: 100%;
    margin: 0 0 40px;
    overflow: hidden;
    .title {
      font-size: 30px;
      margin: 0 auto;
      padding: 30px 0px;
    }
    .cover1,
    .cover2 {
      width: 100px;
      bottom: 0;
      top: 0;
      height: 100%;
    }
    .seamless-warp {
      width: 100%;
      height: 48px;
      ul.ul-item {
        height: 48px;
      }
      .li-item {
        width: 90px;
        height: 48px;
        margin-right: 6px;
      }
    }
  }
}
</style>
