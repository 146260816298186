<template>
  <div class="index-chain full-box" id="index-chain">
    <div class="unfull-box">
      <div class="header">
        <h1 class="title" data-aos="fade-down" data-aos-delay="300">六大战略级版块，助力企业快速发展</h1>
      </div>
      <div class="cont" data-aos="fade-right" data-aos-delay="600">
        <swiper class="index-chain-swiper swiper-container" :options="swiperOptions" ref="mySwiper">
          <!-- 顶部选项 -->
          <div class="index-chain-pagination" slot="pagination"></div>
          <swiper-slide
            class="index-chain-swiper-slide index-chain-swiper-stop"
            v-for="(item, idx) in items"
            :key="idx"
            :data-name="item.title"
          >
            <div class="box-item">
              <div class="left">
                <div class="cont-item">
                  <h1 class="title">{{ item.title }}</h1>
                  <h2 class="sub-title">{{ item.desc }}</h2>
                  <!-- <div class="yi-btn" onclick="qimoChatClick();">了解详情</div> -->
                </div>
              </div>
              <div class="right">
                <img :src="item.image" :alt="item.title" />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
  inject: ['isMobile'],
  components: {
    swiper,
    swiperSlide,
  },
  data(vm) {
    return {
      pagination: [],
      swiperOptions: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000, //可选选项，自动滑动
        },
        noSwiping: vm.isMobile ? false : true,
        noSwipingClass: vm.isMobile ? '' : 'index-chain-swiper-stop',
        pagination: {
          el: '.index-chain-pagination',
          clickable: true,
          renderBullet(index, className) {
            if (!vm.pagination[index]) {
              vm.pagination[index] = $('.index-chain-swiper')
                .find('.index-chain-swiper-slide')
                .eq(index + 1)
                .attr('data-name');
            }
            return (
              '<div data-index="' +
              index +
              '" class="item ' +
              className +
              '"><img src="' +
              require('@images/industry-chain/san.png') +
              '">' +
              vm.pagination[index] +
              '</div>'
            );
          },
        },
      },
      items: [
        {
          title: '品牌前策版块',
          desc: 'AD-VC针对投资项目的前期梳理，核心产品定位及传播启动的内容建议',
          image: require('@images/industry-chain/4.png'),
        },
        {
          title: '广告投放版块',
          desc: '通过重点投放区域的媒体策略规划、媒体选择和投放执行服务',
          image: require('@images/industry-chain/1.png'),
        },
        {
          title: '明星艺人版块',
          desc: '项目服务中核心产品（品牌）引入明星艺人为产品代言，体验服务',
          image: require('@images/industry-chain/2.png'),
        },
        {
          title: '融资版块',
          desc: '被投资项目的发展前、中期投资机构引进，举荐服务',
          image: require('@images/industry-chain/3.png'),
        },
        {
          title: '上市辅导版块',
          desc: 'AD-VC所投资项目启动上市规划后的前期辅导服务',
          image: require('@images/industry-chain/5.png'),
        },
        {
          title: '市值管理版块',
          desc: '被投项目上市后二级市场市值管理服务',
          image: require('@images/industry-chain/6.png'),
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    initData() {
      let vm = this;
      $('.index-chain-pagination .item').hover(
        (e) => {
          let index = $(e.target).attr('data-index');
          vm.swiper.slideTo(Number(index) + 1, 500, false);
        },
        () => {
          vm.swiper.autoplay.start();
        }
      );
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="scss">
.index-chain .index-chain-pagination {
  position: absolute;
  display: flex;
  bottom: auto;
  top: 0;
  z-index: 50;

  ::v-deep .swiper-pagination-bullet {
    width: 360px;
    padding-right: 24px;
    margin: 0 3px;
    border-radius: 0;
    text-align: center;
    opacity: 1;
    background-color: #fff;
    height: 78px;
    font-size: 22px;
    line-height: 78px;
    color: #6d6d6d;
    cursor: pointer;
    img {
      position: relative;
      top: 46px;
      width: 30px;
      height: 30px;
      left: 57px;
      opacity: 0;
    }
    &.swiper-pagination-bullet-active {
      background-color: #bd192f;
      color: #fff;
      img {
        opacity: 1;
      }
    }
  }
}
.index-chain {
  background-image: url('~@images/industry-chain/b1.jpg');
  background-size: 100% 100%;
  height: auto;
  .header {
    text-align: center;
    color: #fff;
    padding-top: 80px;
    padding-bottom: 60px;
    .title {
      font-size: 36px;
    }
    .sub-title {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 50px;
      letter-spacing: 3px;
    }
  }
  .swiper-wrapper {
    height: auto;
  }
  .cont-item {
    color: #fff;
    padding: 130px 15px;
    text-align: left;
    .title {
      font-size: 32px;
      margin-bottom: 25px;
    }
    .sub-title {
      font-size: 18px;
      margin-bottom: 50px;
      font-weight: normal;
    }
  }
}
.box-item {
  display: flex;
  height: 600px;
  margin-top: 90px;
  & > div {
    width: 50%;
  }
  & > .right {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
.index-chain-slide-swiper {
  width: 100%;
  height: 100%;
}

.mobile {
  .index-chain {
    padding: 0 10px;
    .unfull-box {
      width: 100%;
    }
    .header {
      padding: 18px 0 0;
      .title {
        font-size: 21px;
      }
    }
    .index-chain-pagination {
      display: none;
    }
    .cont-item {
      padding: 20px 12px;
      .title {
        font-size: 22px;
        margin-bottom: 12px;
      }
      .sub-title {
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 20px;
      }
    }
    .box-item {
      margin-top: 14px;
      height: auto;
    }
  }
}
</style>
