<template>
  <div class="industry-field unfull-box" id="industry-field">
    <h1 class="title" data-aos="fade-down" data-aos-delay="500">AD-VC双轮驱动模式</h1>
    <h2 class="sub-title" data-aos="fade-right" data-aos-delay="800"></h2>
    <Wheel></Wheel>
  </div>
</template>

<script>
import Wheel from './Wheel.vue';
export default {
  components: {
    Wheel,
  },
  methods: {
    initData() {
      let lastTime = 0;
      $('.industry-field .industries >div').on('animationend', function (e) {
        if (lastTime > Date.now() - 1000) {
          return;
        }
        lastTime = Date.now();
        if (!$(e.target).hasClass('check')) {
          $(e.target).addClass('check');
        }
      });

      $('.industry-field .industries div .content').mouseleave(function (e) {
        if (!$(e.target).hasClass('check')) {
          $(e.target).addClass('check');
        }
      });

      /** 清除炸弹 */
      function removeC4() {
        var c4 = $('.c-4');
        c4.css('animation', 'none').css('z-index', '1');
        c4.find('img').css('animation', 'none');
        c4.find('.content').css('animation', 'none');
      }

      var lastClass = false;
      $('.industry-field .industries .l-1').hover(
        function (e) {
          if ($(e.target).hasClass('check')) {
            return;
          }
          removeC4();
          lastClass = $(e.target).hasClass('c-4');
          $(e.target).css('animation', '1s scale-div forwards').css('z-index', '10');
          $(e.target).find('img').css('animation', '1s scale-img forwards');
          $(e.target).find('.content').css('animation', '1s scale-div forwards');
        },
        function (e) {
          if (lastClass && $(e.target).hasClass('c-4')) {
            return false;
          }
          $(e.target).css('animation', 'none').css('z-index', '1');
          $(e.target).find('img').css('animation', 'none');
          $(e.target).find('.content').css('animation', 'none');
        }
      );

      $('.industry-field .industries .l-2').hover(function () {
        removeC4();
      });

      $('.industry-field .industries .l-1').mouseenter(function (e) {
        $('.industry-field .industries .check').removeClass('check');
        if (!$(e.target).hasClass('check')) {
          $(e.target).addClass('check');
        }
      });

      $('.industry-field .industries .l-2').mouseenter(function (e) {
        $('.industry-field .industries .check').removeClass('check');
        $(e.target).addClass('check');
      });

      let l2LastTime = 0;
      $('.industry-field .industries .l-2 .content').on('animationend', function (e) {
        if (l2LastTime > Date.now() - 1000) {
          return;
        }
        l2LastTime = Date.now();
        $(e.target).addClass('check');
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="scss">
.industry-field {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  .title {
    font-size: 36px;
    color: #232323;
  }
  .sub-title {
    font-size: 16px;
    color: #6d6d6d;
    margin-top: 10px;
    margin-bottom: 50px;
    letter-spacing: 3px;
  }
  .industries {
    display: grid;
    grid-template-columns: 295px 295px 295px 295px;
    grid-template-rows: 295px 295px;
    justify-content: space-around;
    grid-auto-flow: column dense;
    grid-gap: 10px;
    & > div {
      background-color: #f9f9f9;
    }
    div {
      .content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 145px 145px;
        justify-items: center;
        align-items: center;
      }
      &:is(.l-2):hover {
        .content {
          animation: 1s scale-div forwards;
        }
      }
      p {
        font-size: 24px;
        margin: 0;
        width: 100%;
      }
      img {
        width: 150px;
      }
    }
    .l-1 {
      &.check {
        img {
          width: 216px;
        }
      }
    }
    .l-2 {
      &.c-1 {
        &.check {
          height: 600px;
          display: grid;
          grid-column: 1 / 2;
          grid-row: 3 / 1;
          align-items: flex-end;
        }
      }
      &.c-2 {
        &.check {
          height: 600px;
          display: grid;
          grid-column: 2 / 3;
          grid-row: 3 / 1;
          align-items: flex-end;
        }
      }
      &.c-3 {
        &.check {
          height: 600px;
          display: grid;
          grid-column: 3 / 4;
          grid-row: 3 / 1;
          align-items: flex-end;
        }
      }
    }
  }
}
.industry-field .industries .l-1.check,
.industry-field .industries .l-1.check .content,
.industry-field .industries .l-2 .content.check {
  grid-row-start: 1;
  grid-row-end: 3;
  height: 600px;
  background-color: #0046e6;
  color: white;
  grid-template-rows: auto auto;
}

@keyframes scale-img {
  0% {
    width: 150px;
  }
  100% {
    width: 216px;
  }
}
@keyframes scale-div {
  0% {
    grid-row-start: 1;
    grid-row-end: 2;
    height: 295px;
    background-color: #f9f9f9;
    color: black;
    grid-template-rows: 145px auto;
  }
  100% {
    grid-row-start: 1;
    grid-row-end: 3;
    height: 600px;
    background-color: #0046e6;
    color: white;
    grid-template-rows: auto auto;
  }
}

@keyframes scale-div-line-2-1 {
  0% {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
  }
  100% {
    grid-column: 1 / 2;
    grid-row: 3 / 1;
  }
}

.mobile {
  .industry-field {
    &.unfull-box {
      width: 100%;
      padding: 10px 0 30px;
    }
    .title {
      font-size: 30px;
    }
    .sub-title {
      margin: 0;
    }
  }
}
</style>
