<template>
  <div class="full-box" style="background-color: #f8f9fb">
    <div class="service unfull-box" id="index-service">
      <div class="left">
        <p>
          以专业的广告媒介策划经验，借助电视、机场、高铁、户外、影院、社区等多种媒体资源，为投资标的企业打造庞大的<span>宣传推广</span>势能；
        </p>
        <p>
          通过科特勒营销咨询、国浩律师事务所等战略合作伙伴，为企业制定
          <span>品牌服务、营销咨询、上市辅导</span>
          ，帮助企业建立精准的商业战略和竞争策略；
        </p>
        <p>
          引入<span>投融资对接、供应链整合、人力资源引进</span>等各种外部合作资源，为企业量身定制
          <span>快速增长</span>模型。
        </p>
        <p>
          同时，AD-VC基金开发了AD-星球媒体数据化中台（SAAS系统），实时可视化把控媒体传播动态和营销战役全局，助力被投企业做出高效、精准的执行决策。
        </p>
        <div class="hr" v-if="false && currentItem">
          <img :src="currentItem.blueImg" :alt="currentItem.title" />
          <h1 class="title">{{ currentItem.title }}</h1>
          <ul>
            <li>技术服务</li>
            <li>科创服务数字平台</li>
          </ul>
          <p class="introduce">
            通过组织宣传推广行业性交流，整合推广资源，为入园企业提供品牌设计、推广平台搭建、日常推广运营、活动推广、整合营销推广等合作资源。
          </p>
          <div class="btn-detail" onclick="qimoChatClick();">了解详情</div>
        </div>
      </div>
      <div class="right">
        <div class="service-icons">
          <div class="icon" v-for="(item, idx) in items" :key="idx" :data-aos="item.aos">
            <div class="buleImg">
              <img :src="item.blueImg" :alt="item.title" />
            </div>
            <div class="hideImg">
              <img :src="item.hideImg" :alt="item.title" />
            </div>
            <p>{{ item.title }}</p>
          </div>
        </div>
        <img src="@images/service/content.png" alt="9大创新服务" class="content-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentItem: null,
      items: [
        {
          title: '人力资源引进',
          aos: 'fade-up-up',
          blueImg: require('@images/service/icon-8.png'),
          hideImg: require('@images/service/8-this.png'),
        },
        {
          title: '投融资服务',
          aos: 'fade-right',
          blueImg: require('@images/service/icon-2.png'),
          hideImg: require('@images/service/2-this.png'),
        },
        {
          title: '上市辅导',
          aos: 'fade-up-right',
          blueImg: require('@images/service/icon-9.png'),
          hideImg: require('@images/service/9-this.png'),
        },
        {
          title: '企业增长',
          aos: 'fade-right',
          blueImg: require('@images/service/icon-1.png'),
          hideImg: require('@images/service/1-this.png'),
        },
        {
          title: '品牌战略咨询',
          aos: 'fade-down',
          blueImg: require('@images/service/icon-5.png'),
          hideImg: require('@images/service/5-this.png'),
        },
        {
          title: '营销策划',
          aos: 'fade-left',
          blueImg: require('@images/service/icon-6.png'),
          hideImg: require('@images/service/6-this.png'),
        },
        {
          title: '宣传推广',
          aos: 'fade-up-left',
          blueImg: require('@images/service/icon-4.png'),
          hideImg: require('@images/service/4-this.png'),
        },
        {
          title: '数字化创新',
          aos: 'fade-up-left',
          blueImg: require('@images/service/icon-7.png'),
          hideImg: require('@images/service/7-this.png'),
        },
        {
          title: '供应链整合',
          aos: 'fade-up-left',
          blueImg: require('@images/service/icon-3.png'),
          hideImg: require('@images/service/3-this.png'),
        },
      ],
    };
  },
  methods: {
    doHover(idx) {
      this.currentItem = this.items[idx];
    },
    initData() {
      let el = $('.service .right .service-icons .icon');
      let parentEl = $('.service .right .service-icons');

      let radius = 330;

      let avd = -200 / el.length;
      //每一个BOX对应的弧度;
      let ahd = (avd * Math.PI) / 180;

      //中心点横坐标
      let dotLeft = parentEl.width() / 2 - 45;
      //中心点纵坐标
      let dotTop = parentEl.height() / 2 + 50;

      $('.service-icons .checked').find('.buleImg').hide();
      $('.service-icons .checked').find('.hideImg').show();

      el.each((index) => {
        el.css({
          left: Math.cos(ahd * (el.length - 1 - index)) * radius + dotLeft,
          top: Math.sin(ahd * (el.length - 1 - index)) * radius + dotTop,
        });
      });

      let vm = this;
      /** 控制服务显示
       * @param index 执行下标
       * */
      function showServer(index) {
        el.removeClass('checked');
        vm.currentItem = vm.items[index];
        el.removeClass('checked');
        el.eq(index).addClass('checked');
        $('.service-icons .icon').find('.buleImg').show();
        $('.service-icons .icon').find('.hideImg').hide();
        $('.service-icons .checked').find('.buleImg').hide();
        $('.service-icons .checked').find('.hideImg').show();
        $('.hr').fadeOut(300, function () {
          setTimeout(() => {
            $('.hr').fadeIn(300);
          }, 200);
        });
      }

      el.hover(
        function (e) {
          if ($(e.target).hasClass('checked')) {
            return;
          }
          stopTimer();
          showServer($(e.target).index());
        },
        function () {
          startTimer();
        }
      );

      /** 动画开始执行 */
      let timer = null;
      function startTimer() {
        if (timer) return;
        timer = setInterval(() => {
          let index = parentEl.find('.checked').index();
          index = index >= el.length - 1 ? 0 : index + 1;
          showServer(index);
        }, 4000);
      }
      /** 暂停动画执行 */
      function stopTimer() {
        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      }

      startTimer();
      $('.service-icons .checked').find('.hideImg').show();
    },
  },
  mounted() {
    this.$nextTick(() => this.initData());
  },
};
</script>

<style scoped lang="scss">
.service {
  display: flex;
  height: 800px;
  padding: 150px 0;
  justify-content: space-between;
  .left {
    width: 30%;
    p {
      color: #62656a;
      line-height: 1.8;
      text-align: left;
      font-size: 17px;
      margin-bottom: 14px;
      span {
        color: #bd192f;
        font-weight: bold;
      }
    }
    .hr {
      text-align: left;
      .btn-detail {
        width: 150px;
        text-align: center;
        padding: 10px;
        color: white;
        background-color: #bd192f;
        border-radius: 5px;
      }
      .introduce {
        width: 100%;
        font-weight: normal;
        animation: serviceHrIntroduce 1s;
        font-size: 14px;
        color: #828282;
        margin-bottom: 80px;
        &:hover {
          cursor: pointer;
        }
      }
      h2 {
        font-size: 12px;
        color: #828282;
        margin-bottom: 80px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        li {
          background-color: #e0e7f7;
          padding: 5px 15px;
          color: #1352e8;
          border-radius: 10px;
          font-size: 12px;
          margin-right: 20px;
          margin-bottom: 10px;
        }
      }
      img {
        margin: 0px;
        padding: 0px;
      }
      .title {
        font-size: 36px;
        color: black;
        margin: 20px 0px 30px 0px;
        font-weight: 400;
      }
    }
  }
  .right {
    width: 65%;
    height: 500px;
    text-align: center;
    position: relative;

    & > img {
      position: absolute;
      bottom: 0px;
      transform: translate(-50%, -52%);
    }

    .service-icons {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 38px;
      }
      .icon {
        width: 100px;
        height: 100px;
        background-color: white;
        box-shadow: 0px 0px 10px #0000003d;
        border-radius: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px;
        color: #7f7f7f;
        position: absolute;
        top: 100px;
        font-size: 12px;
        transition: 200ms all ease-out;
        &:hover {
          cursor: pointer;
          background-color: #bd192f;
          color: white;
        }
      }
      .checked:hover {
        background-color: #bd192f;
      }
      p {
        margin-bottom: 8px;
      }
      .checked {
        background-color: #bd192f;
        color: white;
        // box-shadow: 0px 0px 20px #0e328586;
      }
      .hideImg {
        display: none;
      }
    }
  }
}

@keyframes serviceHrIntroduce {
  from {
    margin-left: -100px;
  }
  to {
    margin-left: 0;
  }
}
#service {
  background-color: #f9f9f9;
}
.content-img {
  height: 54%;
}
.icon:nth-child(9) {
  left: 179.3031px !important;
  top: 371.5111px !important;
}
.icon:nth-child(1) {
  left: 93.7981px !important;
  top: 223.412px !important;
}
.icon:nth-child(2) {
  left: 123.4936px !important;
  top: 55px !important;
}
.icon:nth-child(3) {
  left: 254.495px !important;
  top: -54.9232px !important;
}
.icon:nth-child(4) {
  left: 425.505px !important;
  top: -54.9232px !important;
}
.icon:nth-child(5) {
  left: 556.5064px !important;
  top: 55px !important;
}
.icon:nth-child(6) {
  left: 586.2019px !important;
  top: 223.412px !important;
}
.icon:nth-child(7) {
  left: 500.6969px !important;
  top: 371.5111px !important;
}
.icon:nth-child(8) {
  left: 340px !important;
  top: 430px !important;
}

.mobile {
  .service {
    padding: 0;
    height: 830px;
    &.unfull-box {
      width: 100% !important;
      overflow: hidden;
    }
    .left {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      padding: 10px 14px;
      margin-top: 460px;
      text-indent: 2em;
      p {
        line-height: 1.6;
        font-size: 15px;
        margin-bottom: 8px;
      }
    }
    .right {
      position: relative;
      transform: scale(0.58);
      width: auto;
      .service-icons {
        left: -66px;
      }
      > img {
        transform: translate(52%, -52%);
      }
    }
  }
}
</style>
