/*
 * 防抖代表同时间内连续触发不执行,用户无动作超过防抖时间后执行
 * key 函数声明标识，防止多个防抖函数冲突
 * fn [function] 需要防抖的函数
 * delay [number] 毫秒，防抖期限值
 */
export const debounce = {
  switch: [],
  call: function (key, fn, delay) {
    return function () {
      if (debounce.switch[key]) {
        clearTimeout(debounce.switch[key]);
      }
      debounce.switch[key] = setTimeout(fn, delay); // 简化写法
    };
  },
  key: function (key) {
    if (debounce.switch[key] === undefined) {
      debounce.switch[key] = null;
    } else {
      console.error('声明防抖函数debounce.key，key值重复声明！');
    }
    return key;
  },
};

/* *
 * 节流表示一段时间内只执行一次
 * fn [function] 需要节流的函数
 * delay [number] 毫秒，节流期限值
 * */
export const throttle = {
  switch: [],
  call: function (key, fn, delay) {
    return function () {
      if (!throttle.switch[key]) {
        //休息时间 暂不接客
        return false;
      }
      // 工作时间，执行函数并且在间隔期内把状态位设为无效
      throttle.switch[key] = false;
      setTimeout(() => {
        fn();
        throttle.switch[key] = true;
      }, delay);
    };
  },
  key: function (key) {
    if (throttle.switch[key] === undefined) {
      throttle.switch[key] = true;
    } else {
      console.error('声明节流函数throttle.key，key值重复声明！');
    }
    return key;
  },
};

/**
 * @effect 防抖代表同时间内连续触发不执行,用户无动作超过防抖时间后执行
 * @param {string} key   函数唯一key
 * @param {func} fn      需要执行方法
 * @param {number} delay       毫秒，防抖期限值
 */
export const Debounce = function (fn, delay) {
  let open = null;
  return function () {
    if (open) {
      clearTimeout(open);
    }
    open = setTimeout(fn, delay); // 简化写法
  };
};

/**
 * @effect 节流表示一段时间内只执行一次
 * @param {string} key   函数唯一key
 * @param {func} fn      需要执行方法
 * @param {number} delay       毫秒，防抖期限值
 */
export const Throttle = function (fn, delay) {
  let open = true;
  return function () {
    if (!open) {
      //休息时间 暂不接客
      return false;
    }
    // 工作时间，执行函数并且在间隔期内把状态位设为无效
    open = false;
    setTimeout(() => {
      fn();
      open = true;
    }, delay);
  };
};
