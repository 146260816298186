<template>
  <div style="position: relative" data-aos="fade-up" data-aos-delay="800">
    <div class="index-chart-wheel" id="echartsWheels"></div>
    <img class="index-chart-wheel-img" src="@images/icon/logo-white.png" />
  </div>
</template>

<script>
export default {
  name: 'Wheel',
  inject: ['isMobile'],
  mounted() {
    this.initCharts();
  },
  data() {
    return {};
  },
  methods: {
    initCharts() {
      const vm = this;
      let wheelsChart = this.$echarts.init(document.getElementById('echartsWheels'));

      // 白色 - 左侧主色 - 右侧主色 - 中心红色 - 黑色
      let colors = ['#ffffff', '#009EFF', '#060544', '#D9001C', '#333333'];
      let leftCenterArr = [
        {
          name: '左侧中心',
          type: 0,
          symbol: 'circle',
          symbolSize: vm.isMobile ? 60 : 160,
          symbolOffset: [vm.isMobile ? -70 : -200, 0],
          itemStyle: {
            color: '#009EFF',
          },
          label: {
            show: true,
            formatter: ['{a|募集}', '{b|各类媒体资源}'].join('\n'),
            rich: {
              a: {
                align: 'center',
                verticalAlign: 'middle',
                fontSize: vm.isMobile ? 15 : 36,
                fontWeight: 'bold',
                color: colors[0],
                lineHeight: vm.isMobile ? 20 : 55,
              },
              b: {
                lineHeight: vm.isMobile ? 12 : 24,
                align: 'center',
                verticalAlign: 'middle',
                fontSize: vm.isMobile ? 8 : 17,
                color: colors[0],
              },
            },
          },
        },
      ];
      let rightCenterArr = [
        {
          name: '右侧中心',
          type: 0,
          symbol: 'circle',
          symbolSize: vm.isMobile ? 60 : 160,
          symbolOffset: [vm.isMobile ? 70 : 200, 0],
          itemStyle: {
            color: '#060544',
          },
          label: {
            show: true,
            formatter: ['{a|投资}', '{b|强广告需求企业}', '{b|（2C企业）}'].join('\n'),
            rich: {
              a: {
                align: 'center',
                verticalAlign: 'middle',
                fontSize: vm.isMobile ? 15 : 36,
                fontWeight: 'bold',
                color: colors[0],
                lineHeight: vm.isMobile ? 20 : 55,
              },
              b: {
                lineHeight: vm.isMobile ? 12 : 24,
                align: 'center',
                verticalAlign: 'middle',
                fontSize: vm.isMobile ? 8 : 17,
                color: colors[0],
              },
            },
          },
        },
      ];

      let leftEdgeArr = [
        {
          name: '户外媒体',
          type: 1,
        },
        {
          name: '电视媒体',
          type: 1,
        },
        {
          name: '网络媒体',
          type: 1,
        },
        {
          name: '明星艺人',
          type: 1,
        },
        {
          name: '营销策划',
          type: 1,
        },
        {
          name: '其他资源',
          type: 1,
        },
      ];
      let rightEdgeArr = [
        {
          name: '消费品企业',
          type: 2,
        },
        {
          name: '国有企业',
          type: 2,
        },
        {
          name: '上市公司',
          type: 2,
        },
        {
          name: '成长型企业',
          type: 2,
        },
        {
          name: '文旅产品',
          type: 2,
        },
        {
          name: '影视/活动',
          type: 2,
        },
        {
          name: '其他2C企业',
          type: 2,
        },
      ];
      let leftArr = [...leftCenterArr, ...leftEdgeArr];
      let rightArr = [...rightCenterArr, ...rightEdgeArr];

      // 点
      function handleArr(arr) {
        arr.forEach((el) => {
          if (el.type > 0) {
            el.symbol = 'circle';
            el.symbolSize = vm.isMobile ? 50 : 100;
            el.itemStyle = {
              color: colors[0],
              borderColor: colors[el.type],
              borderWidth: 2,
              borderType: 'solid',
            };
            if (el.type > 1) {
              el.label = {
                normal: {
                  show: true,
                  align: 'center',
                  verticalAlign: 'middle',
                  fontSize: vm.isMobile ? 9 : 16,
                  fontWeight: vm.isMobile ? 'normal' : 'bold',
                  color: colors[4],
                },
              };
            } else {
              el.label = {
                show: true,
                formatter: ['{a|LP}', `{b|${el.name}}`].join('\n'),
                rich: {
                  a: {
                    align: 'center',
                    verticalAlign: 'middle',
                    fontSize: vm.isMobile ? 18 : 36,
                    fontWeight: vm.isMobile ? 'normal' : 'bold',
                    color: colors[4],
                    lineHeight: vm.isMobile ? 21 : 45,
                  },
                  b: {
                    lineHeight: vm.isMobile ? 11 : 18,
                    align: 'center',
                    verticalAlign: 'middle',
                    fontWeight: vm.isMobile ? 'normal' : 'bold',
                    fontSize: vm.isMobile ? 9 : 15,
                    color: colors[4],
                  },
                },
              };
            }
          }
          return arr;
        });
      }

      handleArr(leftArr);
      handleArr(rightArr);

      // 圆形分区, r - 半径
      function group(arr, r, direction = 'left') {
        const newArray = [];
        const { length: arLen } = arr;
        arr.forEach((e, ind) => {
          const sign = direction === 'left' ? 1 : -1;
          const rectAng = (160 / arLen).toFixed(2) * (ind + 1);
          const ang = 90 + rectAng * sign;
          const x0Offset = -10.7 * sign;
          const x1Offset = -2.1 * sign;
          const x = Math.cos((ang * Math.PI) / 180).toFixed(2) * r;
          const y = Math.sin((ang * Math.PI) / 180).toFixed(2) * r;
          const x0 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 28) + x0Offset;
          const y0 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 28);
          const x1 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 5) + x1Offset;
          const y1 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 5);
          e.value = [x.toFixed(2), y.toFixed(2)];
          e.twoPoint = [
            [x0.toFixed(2), y0.toFixed(2)],
            [x1.toFixed(2), y1.toFixed(2)],
          ];
          newArray.push(e);
        });
        return newArray;
      }

      // 线配置
      function linesConfig(arr) {
        const [dataArr] = [[], [0, 0]];
        arr.forEach((el) => {
          function getFormatItem(start, end) {
            let item = [
              // 起点
              { coord: el.twoPoint[start] },
              // 终点
              {
                coord: el.twoPoint[end],
                lineStyle: {
                  color: colors[el.type],
                  curveness: el.type === 3 ? 0.1 : 0,
                },
                effect: {
                  color: colors[el.type],
                },
              },
            ];
            return item;
          }
          if (el.type === 1 || el.type === 2) {
            dataArr.push(getFormatItem(0, 1));
          }
        });
        return dataArr;
      }

      // 点分布
      leftCenterArr = group(leftCenterArr, 0, 'left');
      leftEdgeArr = group(leftEdgeArr, 35, 'left');
      rightCenterArr = group(rightCenterArr, 0, 'right');
      rightEdgeArr = group(rightEdgeArr, 35, 'right');

      leftArr = [...leftCenterArr, ...leftEdgeArr];
      rightArr = [...rightCenterArr, ...rightEdgeArr];
      // 线坐标和配置
      let leftLineArr = linesConfig(leftArr);
      let rightLineArr = linesConfig(rightArr);

      function getOption(radius) {
        let option = {
          backgroundColor: colors[0],
          grid: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
          xAxis: {
            show: false,
          },
          yAxis: {
            show: false,
          },
          series: [
            {
              name: '中心圆',
              type: 'graph',
              itemStyle: {
                normal: {
                  color: colors[3],
                  shadowColor: 'rgba(217, 0, 28, 0.5)',
                  shadowBlur: 100,
                },
              },
              silent: true,
              z: 3,
              hoverAnimation: false,
              animation: false,
              emphasis: {
                scale: false,
              },
              data: [
                {
                  symbolSize: radius,
                  symbol: 'circle',
                  x: 0,
                  y: 0,
                  label: {
                    show: true,
                    padding: vm.isMobile ? [16, 0, 0, 0] : [24, 0, 0, 0],
                    formatter: ['{c|   }', '{d|GP}'].join('\n'),
                    rich: {
                      c: {
                        align: 'center',
                        verticalAlign: 'middle',
                        fontSize: vm.isMobile ? 10 : 24,
                        color: colors[0],
                        lineHeight: vm.isMobile ? 12 : 40,
                      },
                      d: {
                        lineHeight: vm.isMobile ? 20 : 55,
                        align: 'center',
                        verticalAlign: 'middle',
                        fontWeight: 'bold',
                        fontSize: vm.isMobile ? 18 : 36,
                        color: colors[0],
                      },
                    },
                  },
                },
                {
                  x: 0,
                  y: 0,
                  symbolSize: 0,
                  symbolOffset: [0, vm.isMobile ? 80 : 114],
                  label: {
                    show: true,
                    formatter: ['{e|以货币基金的交易模式}', '{e|进行品牌投资}'].join('\n'),
                    rich: {
                      e: {
                        lineHeight: vm.isMobile ? 18 : 24,
                        align: 'center',
                        verticalAlign: 'middle',
                        fontWeight: 'bolder',
                        fontSize: vm.isMobile ? 12 : 18,
                        color: colors[4],
                      },
                    },
                  },
                },
              ],
            },
            {
              name: '左边箭头',
              type: 'graph',
              silent: false,
              hoverAnimation: false, // 鼠标悬浮高亮
              cursor: 'default',
              animation: false,
              itemStyle: {
                normal: {
                  color: colors[3],
                  shadowColor: 'none',
                },
              },
              emphasis: {
                scale: false,
              },
              data: [
                {
                  symbolSize: vm.isMobile ? [90, 10] : [90, 15],
                  symbolOffset: vm.isMobile ? [-6, 0] : [-65, 0],
                  symbolRotate: 180,
                  x: 0,
                  y: 0,
                  symbol: 'path://M0 0 h 300 v -20 l 80 40 l -80 40 v -20 h -300 z',
                },
              ],
            },
            {
              name: '右边箭头',
              type: 'graph',
              silent: false,
              hoverAnimation: false, // 鼠标悬浮高亮
              cursor: 'default',
              animation: false,
              itemStyle: {
                normal: {
                  color: colors[3],
                  shadowColor: 'none',
                },
              },
              emphasis: {
                scale: false,
              },
              data: [
                {
                  symbolSize: vm.isMobile ? [90, 10] : [90, 15],
                  symbolOffset: vm.isMobile ? [6, 0] : [65, 0],
                  x: 0,
                  y: 0,
                  symbol: 'path://M0 0 h 300 v -20 l 80 40 l -80 40 v -20 h -300 z',
                },
              ],
            },
            {
              name: '左边节点',
              type: 'graph',
              silent: false,
              hoverAnimation: false, // 鼠标悬浮高亮
              cursor: 'default',
              coordinateSystem: 'cartesian2d',
              z: 3,
              itemStyle: {
                normal: {
                  shadowColor: 'none',
                },
              },
              emphasis: {
                scale: false,
              },
              animation: false,
              data: leftArr,
            },
            {
              name: '右边节点',
              type: 'graph',
              silent: false,
              hoverAnimation: false, // 鼠标悬浮高亮
              cursor: 'default',
              coordinateSystem: 'cartesian2d',
              z: 3,
              itemStyle: {
                normal: {
                  shadowColor: 'none',
                },
              },
              emphasis: {
                scale: false,
              },
              animation: false,
              data: rightArr,
            },
            {
              name: '左边连接线',
              type: 'lines',
              hoverAnimation: false,
              silent: false,
              cursor: 'default',
              coordinateSystem: 'cartesian2d',
              polyline: false, // 多线段
              z: 1,
              lineStyle: {
                width: 2,
                type: 'dashed',
                curveness: 0,
              },
              // 箭头特效
              effect: {
                show: true,
                period: 6, //箭头指向速度，值越小速度越快
                trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 6,
              },
              emphasis: {
                lineStyle: {
                  type: 'dashed',
                },
              },
              data: leftLineArr,
            },
            {
              name: '右边连接线',
              type: 'lines',
              hoverAnimation: false,
              silent: false,
              cursor: 'default',
              coordinateSystem: 'cartesian2d',
              polyline: false, // 多线段
              z: 1,
              lineStyle: {
                width: 2,
                type: 'dashed',
                curveness: 0,
              },
              // 箭头特效
              effect: {
                show: true,
                period: 6, //箭头指向速度，值越小速度越快
                trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 6,
              },
              emphasis: {
                lineStyle: {
                  type: 'dashed',
                },
              },
              data: rightLineArr,
            },
            {
              name: '旋转圆弧',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                return {
                  type: 'arc',
                  shape: {
                    cx: api.getWidth() / 2,
                    cy: api.getHeight() / 2,
                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.3 : 0.4),
                    startAngle: ((0 + angle) * Math.PI) / 180,
                    endAngle: ((90 + angle) * Math.PI) / 180,
                  },
                  style: {
                    stroke: colors[3],
                    fill: 'transparent',
                    lineWidth: 1,
                  },
                  silent: true,
                };
              },
              data: [0],
            },
            {
              name: '旋转圆弧',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                return {
                  type: 'arc',
                  shape: {
                    cx: api.getWidth() / 2,
                    cy: api.getHeight() / 2,
                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.3 : 0.4),
                    startAngle: ((180 + angle) * Math.PI) / 180,
                    endAngle: ((270 + angle) * Math.PI) / 180,
                  },
                  style: {
                    stroke: colors[3],
                    fill: 'transparent',
                    lineWidth: 1,
                  },
                  silent: true,
                };
              },
              data: [0],
            },
            {
              name: '旋转圆弧',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                return {
                  type: 'arc',
                  shape: {
                    cx: api.getWidth() / 2,
                    cy: api.getHeight() / 2,
                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.32 : 0.42),
                    startAngle: ((270 + -angle) * Math.PI) / 180,
                    endAngle: ((40 + -angle) * Math.PI) / 180,
                  },
                  style: {
                    stroke: colors[3],
                    fill: 'transparent',
                    lineWidth: 1,
                  },
                  silent: true,
                };
              },
              data: [0],
            },
            {
              name: '旋转圆弧',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                return {
                  type: 'arc',
                  shape: {
                    cx: api.getWidth() / 2,
                    cy: api.getHeight() / 2,
                    r: (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.32 : 0.42),
                    startAngle: ((90 + -angle) * Math.PI) / 180,
                    endAngle: ((220 + -angle) * Math.PI) / 180,
                  },
                  style: {
                    stroke: colors[3],
                    fill: 'transparent',
                    lineWidth: 1,
                  },
                  silent: true,
                };
              },
              data: [0],
            },
            {
              name: '旋转圆弧的端点',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                let x0 = api.getWidth() / 2;
                let y0 = api.getHeight() / 2;
                let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.32 : 0.42);
                let point = getArcPoint(x0, y0, r, 90 + -angle);
                return {
                  type: 'circle',
                  shape: {
                    cx: point.x,
                    cy: point.y,
                    r: 3.5,
                  },
                  style: {
                    stroke: colors[3],
                    fill: colors[3],
                  },
                  silent: true,
                };
              },
              data: [0],
            },
            {
              name: '旋转圆弧的端点',
              type: 'custom',
              coordinateSystem: 'none',
              renderItem: function (params, api) {
                let x0 = api.getWidth() / 2;
                let y0 = api.getHeight() / 2;
                let r = (Math.min(api.getWidth(), api.getHeight()) / 2) * (vm.isMobile ? 0.32 : 0.42);
                let point = getArcPoint(x0, y0, r, 270 + -angle);
                return {
                  type: 'circle',
                  shape: {
                    cx: point.x,
                    cy: point.y,
                    r: 3.5,
                  },
                  style: {
                    stroke: colors[3],
                    fill: colors[3],
                  },
                  silent: true,
                };
              },
              data: [0],
            },
          ],
        };
        return option;
      }

      // 初始旋转角度
      let angle = 0;
      // 初始缩放尺寸
      let [minRadius, radius, maxRadius] = vm.isMobile ? [52, 53, 55] : [125, 130, 135];
      // 缩放动画 标识
      let isBig = false;

      function draw() {
        // 角度变化
        angle = angle + 5;
        // 半径变化
        if (isBig) {
          radius += vm.isMobile ? 1.2 : 2;
          if (radius > maxRadius) {
            isBig = false;
          }
        } else {
          radius -= vm.isMobile ? 1.1 : 1.5;
          if (radius < minRadius) {
            isBig = true;
          }
        }
        let option = getOption(radius);
        wheelsChart.setOption(option, true);
      }

      //获取圆上面某点的坐标(x0,y0表示坐标，r半径，angle角度)
      function getArcPoint(x0, y0, r, angle) {
        let x1 = x0 + r * Math.cos((angle * Math.PI) / 180);
        let y1 = y0 + r * Math.sin((angle * Math.PI) / 180);
        return {
          x: x1,
          y: y1,
        };
      }

      let timerId;
      timerId && clearInterval(timerId);
      timerId = setInterval(() => {
        draw();
      }, 200);
      setTimeout(() => {
        timerId && clearInterval(timerId);
        timerId = setInterval(() => {
          draw();
        }, 100);
      }, 4000);
    },
  },
};
</script>

<style scoped lang="scss">
.index-chart-wheel {
  width: calc(100% - 100px);
  margin: 40px auto 0;
  height: 800px;
}

.index-chart-wheel-img {
  position: absolute;
  top: 374px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 160px;
  padding: 0 20px;
}

.mobile {
  .index-chart-wheel {
    width: calc(100% - 28px);
    height: 330px;
    margin: 20px auto 0;
  }
  .index-chart-wheel-img {
    top: 154px;
    width: 80px;
  }
}
</style>
