<template>
  <div class="full-box index-news" id="index-news">
    <div id="introduce1" class="unfull-box">
      <div class="p">
        AD-VC是国内一家以广告价值投资为核心的创投基金<br />
        专注新消费品牌、新消费模式、2C领域品牌投资<br />
        面向广告公司和发展型企业，双边市场的业务形态<br />
        通过大量广告迅速提升企业的品牌影响力和知名度，实现企业快速增长
      </div>
      <!-- 头部数字 -->
      <div class="header">
        <div class="progress">
          <div></div>
        </div>
        <div class="list">
          <div class="item">
            <div class="item-icon">
              <img src="@images/news/1.png" alt="媒体资源募集" />
            </div>
            <div class="nums"><span class="company_num">600</span>亿+</div>
            <div class="title">媒体资源募集</div>
          </div>
          <div class="item">
            <div class="item-icon">
              <img src="@images/news/5.png" alt="投资企业" />
            </div>
            <div class="nums"><span class="invest_num">100</span>+</div>
            <div class="title">投资企业</div>
          </div>
          <div class="item">
            <div class="item-icon">
              <img src="@images/news/4.png" alt="激活媒体资源" />
            </div>
            <div class="nums"><span class="job_people_num">100</span>亿+</div>
            <div class="title">激活媒体资源</div>
          </div>
          <div class="item">
            <div class="item-icon">
              <img src="@images/news/3.png" alt="服务企业综合产值增长" />
            </div>
            <div class="nums"><span class="output_num">1000</span>亿元</div>
            <div class="title">服务企业综合产值增长</div>
          </div>
          <div class="item">
            <div class="item-icon">
              <img src="@images/news/2.png" alt="2025年目标企业市值" />
            </div>
            <div class="nums"><span class="action_num">10000</span>亿</div>
            <div class="title">2025年目标企业市值</div>
          </div>
        </div>
      </div>

      <!-- 最新动态 -->
      <div v-if="isMobile" class="cont" data-aos="fade-down">
        <div class="list">
          <h4>最新动态</h4>
          <div class="img">
            <div class="swiper-container index-news-img-swiper">
              <swiper :options="swiperOptions" ref="mySwiper">
                <swiper-slide v-for="(e, idx) in newsList" :key="idx">
                  <div @click="clickItem(e)">
                    <img :src="e.cover" :alt="e.title" style="cursor: pointer" />
                    <div class="a">{{ e.title }}</div>
                    <div class="date">{{ e.publishTime }}</div>
                  </div>
                </swiper-slide>
                <div class="index-news-img-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="cont box-shadow" data-aos="fade-down">
        <div class="img">
          <div class="swiper-container index-news-img-swiper">
            <swiper :options="swiperOptions" ref="mySwiper">
              <swiper-slide v-for="(e, idx) in newsList.filter((t) => t.cover)" :key="idx">
                <img :src="e.cover" @click="clickItem(e)" :alt="e.title" style="cursor: pointer" />
              </swiper-slide>
              <div class="index-news-img-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
        <div class="list">
          <h4>最新动态</h4>
          <div class="index-news-list-swiper" style="text-align: left">
            <div class="item" v-for="(item, idx) in newsList" :key="idx" @click="clickItem(item)">
              <div class="dian"></div>
              <div class="a">{{ item.title }}</div>
              <div class="date">{{ item.publishTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@/utils';
import anime from 'animejs';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  inject: ['isMobile'],
  data() {
    return {
      newsList: [],
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 3000, //可选选项，自动滑动
        },
        pagination: {
          el: '.index-news-img-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<div data-index="' + index + '" class="item ' + className + '"></div>';
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    initData() {
      var company_num = $('.full-box .company_num');
      var action_num = $('.full-box .action_num');
      var output_num = $('.full-box .output_num');
      var job_people_num = $('.full-box .job_people_num');
      var invest_num = $('.full-box .invest_num');

      var myObject = {
        company_num: 0,
        action_num: 0,
        output_num: 0,
        job_people_num: 0,
        invest_num: 0,
      };

      var animation = anime({
        targets: myObject,
        company_num: 600,
        action_num: 10000,
        output_num: 1000,
        job_people_num: 100,
        invest_num: 100,
        easing: 'linear',
        round: 1,
        update: function () {
          company_num.text(myObject.company_num);
          action_num.text(myObject.action_num);
          output_num.text(myObject.output_num);
          job_people_num.text(myObject.job_people_num);
          invest_num.text(myObject.invest_num);
        },
      });

      var open = 1; //动画执行开关
      var throttleKey = throttle.key('numsAnimation');
      $(window).scroll(function () {
        throttle.call(
          throttleKey,
          () => {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= 340 && open) {
              open = 0;
              animation.restart();
            } else if (scrollTop < 100) {
              open = 1;
            }
          },
          300
        )();
      });
    },
    async queryInformationPage() {
      let res = await this.$api.informationQueryInformationPage({
        pageNo: 1,
        pageSize: 5,
        publishStatus: 'PUBLISHED',
      });
      this.newsList = res.list || [];
    },
    clickItem(item) {
      let obj = { path: `/article/${item.id}` };
      if (this.isMobile) {
        this.$router.push(obj);
      } else {
        this.$router.replace(obj);
      }
    },
  },
  mounted() {
    this.initData();
    this.queryInformationPage();
  },
};
</script>

<style scoped lang="scss">
.swiper-container {
  width: 485px;
  height: 285px;
  overflow: hidden;
  ::v-deep .swiper-slide {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  ::v-deep .index-news-img-pagination {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    z-index: 20;
    background: rgba(0, 0, 0, 0.25);
    text-align: left;
    width: auto;
    transition: all 0.3s ease-in-out;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: #ffffff;
      border-radius: 50%;
      opacity: 0.5;
      margin-right: 8px;
      &.swiper-pagination-bullet-active {
        width: 24px;
        height: 8px;
        background: #ffffff;
        opacity: 0.5;
        border-radius: 10px;
      }
    }
  }
}
.index-news {
  padding: 100px 0;
  background-color: #f0f0fa;
  .box-shadow {
    transition: all 0.15s ease-out;
    &:hover {
      box-shadow: #999 5px 3px 6px;
    }
  }
  .unfull-box {
    .p {
      text-align: center;
      color: #232323;
      font-size: 24px;
      line-height: 1.8;
      padding-bottom: 20px;
    }
    .yi-btn {
      margin: 60px auto;
      font-size: 16px;
    }
  }
  .header {
    .progress {
      background-color: #3d080f;
      height: 16px;
      div {
        height: 100%;
        width: 30%;
        background-color: #bd192f;
      }
    }
    .list {
      display: flex;
      padding: 40px;
      background-color: #fff;
      .item {
        width: 20%;
        text-align: center;
        .item-icon {
          margin-bottom: 20px;
          width: 100px;
          height: 100px;
          left: 50%;
          position: relative;
          transform: translateX(-50%);
          img {
            width: 100px;
            height: 100px;
          }
        }
        .nums {
          font-size: 12px;
          color: #5e5e5e;
          margin-bottom: 10px;
          span {
            font-size: 28px;
            font-weight: bold;
            color: #222;
            margin-right: 3px;
          }
        }
        .title {
          font-size: 16px;
          color: #5e5e5e;
        }
      }
    }
  }
  .cont {
    height: 380px;
    background-color: #fff;
    margin-top: 40px;
    display: flex;
    .img {
      width: 510px;
      height: 100%;
      padding: 35px 0;
      padding-left: 25px;
    }
    .list {
      width: calc(100% - 510px);
      height: calc(100% - 60px);
      padding: 30px 50px;
      h4 {
        color: #232323;
        margin: 0 10px;
      }
      .item {
        position: relative;
        padding-left: 28px;
        margin-top: 15px;
        .a {
          display: block;
          font-size: 18px;
          color: #777;
          margin: 8px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 400;
          cursor: pointer;
        }
        .dian {
          left: 5px;
          top: 6px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #bd192f;
          position: absolute;
        }
        .date {
          font-size: 12px;
          color: #999;
        }
      }
      .swiper-slide {
        .item {
          &:last-child {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    height: 192px;
    margin-top: 12px;
    a {
      width: 49.5%;
      cursor: pointer;
      &:last-child {
        margin-left: 1%;
      }
    }
    .item {
      width: 100%;
      height: 201px;
      padding: 20px;
      display: flex;
      background-color: #fff;
      .footer-cont {
        width: calc(100% - 268px);
        position: relative;
        .describe {
          font-size: 14px;
          color: #777;
          margin-bottom: 40px;
          max-height: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        span {
          color: #0046e6;
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          bottom: 0;
        }
      }
      .img {
        width: 268px;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
      h1 {
        padding-right: 15px;
        font-size: 18px;
        color: #777;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
.index-news-list-pagination {
  & > div {
    height: 2px;
    width: 36px;
    border-radius: 0;
  }
}

.mobile {
  .index-news {
    padding: 0 0 10px;
    .unfull-box {
      width: 100%;
      .p {
        font-size: 14px;
        padding: 30px 14px;
      }
    }
    .header {
      .progress {
        height: 5px;
        div {
          width: 20%;
        }
      }
      .list {
        padding: 10px;
        .item {
          width: 20%;
          height: auto;
          .title {
            font-size: 12px;
            transform: scale(0.85);
          }
          .nums {
            margin-bottom: 5px;
            span {
              font-size: 15px;
              margin-right: 2px;
            }
          }
          .item-icon {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }

    .cont {
      margin-top: 14px;
      height: auto;
      .img {
        width: 100%;
        height: 100%;
        padding: 10px 10px 0;
        .swiper-container {
          width: 100%;
          box-shadow: 0px 0px 6px #0000003d;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 220px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            overflow: hidden;
            object-fit: cover;
          }
          .a {
            display: block;
            font-size: 15px;
            text-align: left;
            padding: 0 8px;
            color: #777;
            margin: 8px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 400;
            cursor: pointer;
          }
          .date {
            font-size: 12px;
            padding: 0 8px;
            color: #999;
            text-align: left;
          }
          .index-news-img-pagination {
            text-align: center;
            background: rgba(0, 0, 0, 0.15);
            padding: 1px 10px;
            bottom: 64px;

            .swiper-pagination-bullet {
              width: 6px !important;
              height: 6px !important;
              &.swiper-pagination-bullet-active {
                width: 20px !important;
                height: 6px !important;
                border-radius: 6px !important;
              }
            }
          }
        }
      }
      .list {
        width: 100%;
        height: auto;
        padding: 14px 0;
        margin: 0 auto;
      }
    }
  }
}
</style>
